<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <shipping-information></shipping-information>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ShippingInformation from "../../components/shipping/ShippingInformation.vue";

export default {
  name: 'InnerShippingInformation',
  components: {
    ShippingInformation,
  },
  props: {
    //
  },
  async beforeMount() {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
